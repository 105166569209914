import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Fragment, useEffect, useState } from "react";
import { ORDER_PSEUDO_NAMES } from "../../../constants/orderTypes";
import withLocalContext from "../../store/withLocalContext";
import dayjs from "dayjs";
import { ORDER_FROM_LOCATIONS } from "../../../constants/locations";
import { Dropdown } from "../../Common/Components/Dropdown";
import List from "../../Common/Components/List";
import TimeDropdown from "../../Common/Components/TimeDropdown";
import { toUSD } from "../../Common/Functions/toUSD";
import AddressLookupInput from "../../Common/AddressLookupInput";
import { findDistance } from "../../../services";
import Calendar from "../../Common/Components/Calendar";
import SubmitBtn from "./SubmitBtn";
import PickupTime from "./PickupTime";
import { useScreenWidth } from "../../Common/Functions/useScreenWidth";
import { setPopupHTML } from "../../store/actions";
import styled from "styled-components";
import { findInitialDate } from "../../Common/Functions/findInitialDate";
import filterLocations from "../../Common/Functions/filterLocations";

const StyledBtn = styled.button`
  width: 100px; // needed for overiding styles
`;

const CartConfiguration = ({
  orderType,
  pickup,
  orderAttributes,
  canTriggerfromMenuItem,
  containerId,
  proposedNewConfig, // used when showing a potential new config state. Canceling out of the popup should keep the current state in place
  context: { state, dispatch }
}) => {

  const lap = useScreenWidth("isLaptop");
  const [canSeePickup, setCanSeePickup] = useState(pickup);
  const [types, setTypes] = useState();
  const [deliveryTypes, setDeliveryTypes] = useState([]);
  const [storeLocations, setStoreLocations] = useState([]);
  const [addressDistance, setAddressDistance] = useState();
  const [isError, setIsError] = useState(false);
  const cartConfig = proposedNewConfig ? proposedNewConfig : state.cartConfig;

  const initialState = {
    deliveryType: { name: "" },
    orderType: orderType ? orderType : { name: "None selected", id: "None selected" },
    address: "",
    date: setInitialDate(),
    time: "",
    location: ""
  }

  const cartConfigState = {
    deliveryType: cartConfig?.deliveryType ? cartConfig.deliveryType : initialState.deliveryType,
    orderType: cartConfig?.orderType ? cartConfig.orderType : initialState.orderType,
    address: cartConfig?.address ? cartConfig.address : initialState.address,
    date: dayjs(cartConfig?.date ? cartConfig.date : initialState.date).format(),
    time: cartConfig?.time ? cartConfig.time : initialState.time,
    location: cartConfig?.location ? cartConfig.location : initialState.location
  }

  // let clone = JSON.parse(JSON.stringify(initialState))

  const [config, setConfig] = useState(cartConfig ? cartConfigState : JSON.parse(JSON.stringify(initialState)));

  const { isMenuLoading, deliveryMethods } = state;

  useEffect(() => {
    if (state.siteData.blackout_dates) {
      const newDate = findInitialDate(state.siteData.blackout_dates[0].items, initialState.date);
      setConfig(item => { return { ...item, date: dayjs(newDate) } });
    }
    // eslint-disable-next-line
  }, [state.siteData]);

  // set order types
  useEffect(() => {
    let tempTypes = ORDER_PSEUDO_NAMES;

    if (!state.availableForSameDay) {
      tempTypes = tempTypes.filter(item => item.id !== "same");
    }

    if (canTriggerfromMenuItem) {
      tempTypes = tempTypes.filter(item => item.id === "same");
    }

    setTypes(tempTypes);
  }, [state.availableForSameDay, canTriggerfromMenuItem]);

  // set store locations
  useEffect(() => {
    let tempLocations = [];

    if (!!canTriggerfromMenuItem) {
      tempLocations = [...filterLocations(ORDER_FROM_LOCATIONS, canTriggerfromMenuItem.orderTypes)]
    } else {
      tempLocations = [...ORDER_FROM_LOCATIONS.map(i => ({ id: i.title, ...i }))];
    }

    if (config.orderType.id.includes("same")) {
      tempLocations = tempLocations.filter(item => !item.id.includes("FRGMNT"));
      // if (dayjs().day() === 6) { // 6 === saturday
      //   tempLocations = tempLocations.filter(item => item.id !== "IDS Center");
      // }
    }

    setStoreLocations(tempLocations);
  }, [config.orderType, canTriggerfromMenuItem])

  useEffect(() => {
    if (!isMenuLoading) {
      let tempDelivery = [];
      deliveryMethods.itemData.variations.map(variation => {
        let description = "";
        if (variation.itemVariationData.name.includes("Metro")) {
          description = "We deliver within 20 miles of downtown. Our standard delivery window is between 7:30 and 10am.";
        } else if (variation.itemVariationData.name.includes("Skyway")) {
          description = "Downtown skyway connected buildings only.";
        }
        tempDelivery.push(
          {
            id: variation.id,
            name: `${variation.itemVariationData.name} (${toUSD(variation.itemVariationData.priceMoney.amount)})`,
            description: description
          });
        return variation;
      })
      if (!state.availableForMetroDelivery) {
        tempDelivery = tempDelivery.filter(item => !item.name.includes("Metro"));
      }
      setDeliveryTypes(tempDelivery);
    }
  }, [isMenuLoading, deliveryMethods, state.availableForMetroDelivery]);

  // if canTriggerfromMenuItem is present then run logic to check for location tags
  useEffect(() => {
    if (canTriggerfromMenuItem) {
      if (JSON.stringify(canTriggerfromMenuItem.tags).includes("City")) {
        setConfig({ ...config, location: "City Center" });
      }
      if (JSON.stringify(canTriggerfromMenuItem.tags).includes("IDS")) {
        setConfig({ ...config, location: "IDS Center" });
      }
      setConfig({ ...config, orderType: ORDER_PSEUDO_NAMES.filter(el => el.id === "same")[0] })
    }
    // eslint-disable-next-line
  }, [])

  const checkAddress = async (value) => {
    let res = await findDistance({ address: value });
    setAddressDistance(res.data.distance);
    if (res.data.distance <= 20) {
      setConfig({ ...config, address: value })
    } else if (config.address) {
      let tempConfig = config;
      delete tempConfig.address;
      setConfig(tempConfig)
    }
  }

  // declared rather than expressed as it is used above in initialState
  function setInitialDate() {
    return dayjs().add((dayjs().day() === 6 ? 2 : 1), 'd')
  }

  const findLocationDate = (locationAvailibility, dateToCheck) => {
    return locationAvailibility.find(l => l.day === dayjs(dateToCheck).format('dddd'))
  }

  // The goal of this function is to ensure that the config date is
  // an actual date that the location has available.
  const setDate = (location) => {
    let validLocationDate = config.date
    const locationDay = findLocationDate(location[0].orderAvailibility, config.date);
    if (!locationDay) {
      let numOfDaysAdded = 0;
      let locationDateCheck = findLocationDate(location[0].orderAvailibility, setInitialDate());
      while (!locationDateCheck) {
        numOfDaysAdded++;
        locationDateCheck = findLocationDate(location[0].orderAvailibility, dayjs(setInitialDate()).add(numOfDaysAdded, 'd'));
      }
      validLocationDate = dayjs(setInitialDate()).add(numOfDaysAdded, 'd');
    }
    return validLocationDate;
  }

  return (
    <Grid2 container className="p-width-100 height-fit">
      {canSeePickup ?
        <PickupTime
          orderAttributes={orderAttributes}
          setCanSeePickup={(val) => setCanSeePickup(val)}
          setOrderType={(val) => setConfig({ ...config, orderType: val })}
        />
        :
        <Grid2 container xs={12} className={`${lap ? "p-l-20 p-r-20" : ""}`}>
          <Grid2 container xs={12} alignItems="center" sx={lap ? { width: "400px" } : {}}>
            <h2 className={`font-size-20 m-r-10 ${lap ? "" : "m-b-10"}`}>
              Your Order Type:
            </h2>
            <Dropdown
              options={types}
              initialOpt={config.orderType}
              isCartConfig
              catId={"orderType"}
              selectedValue={(val) => {
                setConfig({ ...initialState, orderType: val[0] });
                setIsError(false);
              }}
              styles={{
                disPadL: "m-x-5"
              }}
            />
          </Grid2>
          <Grid2 container xs={12}>
            <p className="m-a-0 m-t-13 font-size-14 text-align-left color-brown">*May affect item availability. Not all products are available at all locations</p>
          </Grid2>
          {config.orderType !== "None selected" &&
            <Grid2 container xs={12}>
              <Grid2 container xs={12} className={`background-color-tan height-1 p-width-100 ${lap ? "m-t-35" : "m-t-25"} m-b-20`} />
              <Grid2 container xs={12} flexDirection="column">
                {config.orderType.id.includes("delivery") ?
                  <Fragment>
                    <h2 className=" font-size-20 color-red text-align-left m-b-25">Delivery Type</h2>
                    <List
                      options={deliveryTypes}
                      isCartConfig
                      initialOpt={config.deliveryType}
                      singleSelect
                      selectedValue={(val) => {
                        setConfig({
                          ...config,
                          deliveryType: { ...val[0] }
                        });
                        setIsError(false);
                      }}
                    />
                    <Grid2 xs={12} className={`${lap ? "m-b-43" : "m-b-23"}`}>
                      <h2 className=" font-size-20 color-red text-align-left m-b-25">Address</h2>
                      <AddressLookupInput initialValue={config.address} selectedValue={(val) => {
                        checkAddress(val);
                        setIsError(false);
                      }} />
                      {addressDistance > 20 &&
                        <span className="d-flex  m-l-20 m-t-10 -m-b-29 futura-cond">This address is out of our 20 mile radius.</span>
                      }
                    </Grid2>
                  </Fragment>
                  :
                  <Fragment>
                    <h2 className={`font-size-20 color-red text-align-left ${lap ? "m-b-25" : "m-b-15"}`}>Locations</h2>
                    {storeLocations.length > 0 &&
                      <List
                        options={storeLocations}
                        initialOpt={{ name: config.location }}
                        isCartConfig
                        singleSelect
                        selectedValue={(val) => {
                          setConfig({
                            ...config,
                            date: setDate(val),
                            location: val[0].name
                          });
                          setIsError(false);
                        }}
                      />
                    }
                  </Fragment>
                }
              </Grid2>
              <Grid2 container xs={12} className="background-color-tan height-1 p-width-100 m-t-5 m-b-20" />
              <Grid2 container xs={12}>
                <Grid2 xs={12}>
                  <h2 className={`font-size-20 color-red text-align-left ${lap ? "m-b-25" : "m-b-15"}`}>{!config.orderType.id.includes("same") ? "When" : "Time"}</h2>
                </Grid2>
                {
                  !config.orderType.id.includes("same") &&
                  <div className={`${lap ? "m-r-24 width-150" : "width-110 m-r-7 m-b-10"}`}>
                    <Calendar
                      title="Select pickup time"
                      subtitle="Choose from the available time slots for your order"
                      containerId={containerId}
                      date={config.date}
                      cartConfig={config}
                      calDate={(val) => {
                        setConfig({ ...config, date: dayjs(val) });
                        setIsError(false);
                      }} />
                  </div>
                }
                {
                  !config.deliveryType.name.includes("Metro") &&
                  <div className={`${lap ? "m-r-24" : "m-r-7"}`}>
                    <TimeDropdown
                      orderType={config.orderType}
                      location={config.location}
                      initialOpt={config.time}
                      calendarDate={config.date || ''}
                      deliveryType={config.deliveryType}
                      selectedValue={(val) => {
                        setConfig({ ...config, time: val });
                        setIsError(false);
                      }}
                    />
                  </div>
                }
              </Grid2>
            </Grid2>
          }
          <Grid2
            container
            xs={12}
            className="m-t-45 popup-button-gradient"
            sx={{
              '>button': { padding: "0 !important" },
              paddingTop: "10px"
            }}
          >
            <div className="border-2-red d-flex justify-between">
              <StyledBtn
                className="red-button-flat"
                onClick={() => dispatch(setPopupHTML(""))}
              >
                Cancel
              </StyledBtn>
              <SubmitBtn
                type={config.orderType}
                config={config}
                isError={isError}
                setIsError={(val) => setIsError(val)}
                canTriggerfromMenuItem={canTriggerfromMenuItem}
              />
            </div>
          </Grid2>
        </Grid2>
      }
    </Grid2 >
  )
}

export default withLocalContext(CartConfiguration);